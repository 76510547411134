<template>
  <div>
    <div>
      <div class="d-flex justify-content-between">
        <h4>Filtros</h4>
        <div v-if="exchangeSubscriptions.length">
          <b>Total de Registros: </b> <b-badge variant="success" >  {{exchangeSubscriptions.length}} </b-badge>
        </div>
      </div>
      <validation-observer tag="form" ref="filterSubscriptions">
        <b-form @submit.prevent="searchByFilter">
          <b-row>
            <b-col md="">
              <validation-provider name="resort" rules="">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Resort</label>
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="searchFilter.resort"
                    :clearable="false"
                  >
                    <option :value="null">Seleccione una opción</option>
                    <option v-for="h in hotels" :key="h.id" :value="h.id"> {{ h.name }}</option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col class="label-text" md="">
              <validation-provider name="número vivienda" rules="">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Vivienda</label>
                  <b-form-input
                    v-model="searchFilter.numberHousing"
                    :state="errors[0] ? false : valid ? true : null"
                    placeholder="Número de vivienda"
                  />
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="">
              <validation-provider name="membresía" rules="">
                <b-form-group slot-scope="{ valid, errors }">
                  <label>Membresía</label>
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="searchFilter.membership"
                    :clearable="false"
                  >
                    <option :value="null">Seleccione una opción</option>
                    <option v-for="membership in membershipsOptions" :key="membership.id" :value="membership.id"> {{ membership.code }}</option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col/>
            <b-col/>
            <b-col cols="">
              <div class="float-right">
                <b-button
                  variant="warning"
                  :disabled="isLoadingData "
                  @click="resetTable"
                > Reset
                </b-button>
                <b-button
                  variant="success"
                  class="mr-1 ml-1"
                  :disabled="isLoadingData || exchangeSubscriptions.length < 1"
                  @click="getExcel"
                > Excel
                </b-button>
                <b-button
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class=""
                  :disabled="isLoadingData"
                > Buscar
                </b-button>
              </div>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </div>

    <hr v-if="exchangeSubscriptions.length">

    <b-row v-if="exchangeSubscriptions.length" class="mb-1">
      <b-col md="2">
        <label>Numero de registros</label>
        <v-select
          v-model="porPagina"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector"
          @input="changePerPage"
        />
      </b-col>
      <b-col md="4">
        <label>Buscar en tabla</label>
        <div class="d-flex">
          <b-form-input
            v-model="search"
            class="mr-1"
            placeholder="Buscar..."
            type="search"
            @input="searchInRecords"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  props: {
    exchangeSubscriptions: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    searchFilter: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    isLoadingData: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    vSelect,
  },
  data() {
    return {
      subcondosfiltered: null,

      porPagina: this.perPage,
      statusFilter: null,
      search: this.searchQuery,
    };
  },
  computed: {
    ...mapState('start', ["hotels"]),
    ...mapState('fivesClubCatalogs', ['membershipsOptions']),
  },
  methods: {
    changePerPage(perPage) {
      this.$emit('change-per-page', perPage);
      this.porPagina = perPage;
    },
    searchInRecords( query ){
      this.$emit('search-in-table-records', query)
      this.search = query
    },
    async resetTable(){
      this.$refs.filterSubscriptions.reset()
      this.porPagina = 10
      this.$emit("reset-filter")
    },
    async getExcel(){
      this.searchFilter.excel = true
      await this.searchByFilter()
      this.searchFilter.excel = null
    },
    async searchByFilter() {
      const valid = await this.$refs.filterSubscriptions.validate()
      if (valid) {
        if (
          !this.searchFilter.resort &&
          !this.searchFilter.numberHousing &&
          !this.searchFilter.membership
        ) this.searchFilter.all = true
        else this.searchFilter.all = null
        this.$emit("look-per-filter", this.searchFilter);
      }
    },
  },
}
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}

.paddler {
  padding-left: 1rem;
}

.custom-margin-buttons{
  margin-top: 1.7rem;
}
</style>