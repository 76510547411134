<template>
    <div>
        <b-card>
            <SubscriptionFilters
                :searchFilter="searchFilter"
                :perPage="perPage"
                :perPageOptions="perPageOptions"
                :searchQuery="searchQuery"
                :exchangeSubscriptions="listExchangeSubscriptions"
                :isLoadingData="isLoadingExchangeSubscriptions ||isUpdatingExchangeSubscriptions"
                @change-per-page="changePerPage"
                @search-in-table-records="searchInTableRecords"
                @look-per-filter="filteredSearch"
                @reset-filter="resetFilter"
            />
            <b-table
                :items="filteredExchangeSubscriptions"
                :fields="tableColumns"
                responsive
                small
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                :sort-desc.sync="isSortDirDesc"
                :busy.sync="isLoadingExchangeSubscriptions"
                primary-key="id"
                show-empty
                class="position-relative mb-0 "
                :thead-class="(filteredExchangeSubscriptions.length) ? '' : 'd-none'"
            >

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                    <strong> Cargando...</strong>
                  </div>
                </template>
                <template #empty>
                    <b-alert show variant="warning" class="p-1 text-center mt-2">No hay registros</b-alert>
                </template>

                <template #cell(status)="data">
                    <b-badge pill :variant="data.item.status ? 'success' : 'danger'" >{{data.value}}</b-badge>
                </template>
            </b-table>

            <PaginationTable
                class="mt-1"
                :currentPage="currentPage"
                :totalRows="listExchangeSubscriptions.length"
                :perPage="perPage"
                @change-page="changePage"
                v-show="listExchangeSubscriptions.length"
            />
        </b-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive'
import { acl } from "@/modules/auth/mixins/acl"
import { sweetAlert, showAlertMessage, formatDateSpecific } from '@/helpers/helpers'
import SubscriptionFilters from "@/modules/fivesClub/components/catalogs/subscriptions/SubscriptionFilters"
import PaginationTable from "@/modules/fivesClub/components/catalogs/subscriptions/PaginationTable"

export default {
    mixins: [acl],
    directives: { Ripple, },
    components: {
        SubscriptionFilters,
        PaginationTable
    },
    async created(){
        await this.init()
    },
    data(){
        return {
            isLoadingExchangeSubscriptions: false,
            isUpdatingExchangeSubscriptions: false,
            listExchangeSubscriptions: [],
            tableColumns: [
                { key:'resortsname', class:'text-center', tdClass: 'p-1', label: 'Resort'},
                { key:'housingnumber', class:'text-center', tdClass: 'p-1', label: 'Vivienda'},
				{ key:'ownerName', class:'text-center', tdClass: 'p-1', label: 'Suscriptor'},
				{ key:'createDate', class:'text-center', tdClass: 'p-1', label: 'Fecha de suscripción', formatter: value => {
                    return formatDateSpecific({date: value})
                }},
				{ key:'status', class:'text-center', tdClass: 'p-1', label: 'Estatus', formatter: value => {
                    return value ? 'Activo' : 'Inactivo'
                } },
				// { key:'actions', class:'text-center', tdClass: 'p-1', label: 'Acciones', class:'text-center'}
            ],
            // hotel, vivienda, membresía, fecha de suscripción, suscriptor
            searchFilter: {
                resort:null,
                numberHousing: null,
                membership: null,
                excel: null,
                all: true
            },
			filterOn: ["resortsname", "housingnumber", "ownerName" ],
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 25, 50, 100],
            searchQuery: '',
            sortBy: 'id',
            isSortDirDesc: true,
        }
    },
    computed: {
        // status 4, es rechazada
        ...mapState('start', ["hotels"]),
        ...mapState("auth", ["user"]),
        ...mapState('fivesClubCatalogs', ['membershipsOptions']),
		filteredExchangeSubscriptions(){
			const items = this.listExchangeSubscriptions
			return items.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
		},
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ["fetchExchangeSubscriptions", "getInitialContentForSubscriptions", "fetchExchangeSubscriptionsExcel"]),
        ...mapMutations('start',['setHotels']),
        async init(){
            this.isLoadingExchangeSubscriptions = true

            await this.getInitialContentForSubscriptions({
                hotels: !this.hotels?.length > 0,
                memberships: !this.membershipsOptions?.length > 0
            })

            // const exchangeSubscriptions = await this.fetchExchangeSubscriptions({all: true})
            // exchangeSubscriptions.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
            // this.listExchangeSubscriptions = exchangeSubscriptions

            this.isLoadingExchangeSubscriptions = false
        },
        changePage(page){
            this.currentPage = page
        },
        changePerPage(perPage){
            this.perPage = perPage
        },
        searchInTableRecords(searchQuery){
            this.searchQuery = searchQuery
        },
        resetFilter(){
            this.searchFilter = {
                resort:null,
                numberHousing: null,
                membership: null,
                excel: null,
                all: true
            },
            this.listExchangeSubscriptions = []
        },
        async filteredSearch(payload){
            this.isLoadingExchangeSubscriptions = true
            if (payload.excel) await this.fetchExchangeSubscriptionsExcel(payload)
            else {
                const exchangeSubscriptions = await this.fetchExchangeSubscriptions(payload)
                exchangeSubscriptions.sort( (a,b) => { return new Date(b.createDate) - new Date(a.createDate) })
                this.listExchangeSubscriptions = exchangeSubscriptions
            }
            this.isLoadingExchangeSubscriptions = false
        },
    },
};
</script>

<style scoped>
.head-btn{
display: flex;
justify-content: flex-end;
margin-bottom: 1rem;
}

.actions-status{
display: flex;
align-items: center;
gap: 1rem;
}

.table-text{
    font-size: 0.8rem;
}
.link-text{
    text-decoration: underline solid 1.5px !important;
}
</style>
